











import {
  computed,
  defineComponent,
  PropType,
  toRefs,
  watch,
} from '@nuxtjs/composition-api';
import '@phosphor-icons/web/src/regular/style.css';
import '@phosphor-icons/web/src/bold/style.css';
import '@phosphor-icons/web/src/fill/style.css';

type IconType = 'thin' | 'light' | 'regular' | 'bold' | 'fill' | 'duotone';

const baseWeights = ['regular', 'bold', 'fill'];

export default defineComponent({
  name: 'Icon',
  props: {
    name: {
      type: String as PropType<string>,
      required: true,
    },
    type: {
      type: String as PropType<IconType | undefined>,
      required: false,
      default: 'regular',
      validator: (type: any) => {
        return ['thin', 'light', 'regular', 'bold', 'fill', 'duotone'].includes(
          type
        );
      },
    },
    inline: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { name, type } = toRefs(props);

    const iconClass = computed(() => {
      let classes = `ph-${name.value} ph`; // regular weight just uses ".ph" class
      type.value !== 'regular' && (classes += `-${type.value}`);
      return classes;
    });

    watch(
      type,
      async (weight) => {
        if (weight && baseWeights.includes(weight)) return;
        // dynamically import non-standard font weights if needed
        if (weight === 'thin')
          // @ts-ignore
          await import('@phosphor-icons/web/src/thin/style.css');
        if (weight === 'light')
          // @ts-ignore
          await import('@phosphor-icons/web/src/light/style.css');
        if (weight === 'duotone')
          // @ts-ignore
          await import('@phosphor-icons/web/src/duotone/style.css');
      },
      { immediate: true }
    );

    return { iconClass };
  },
});

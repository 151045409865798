import { plainToClass } from 'class-transformer';
import { PartialApiFactory } from '../partial-factory';
import { IApiFactory } from '../../IApiFactory';
import { AlertSetting } from '~/app/models';

export const createAlertsApi: PartialApiFactory<IApiFactory, 'alerts'> = (
  api
) => {
  return {
    async getAsync() {
      const alerts = await api.getAsync<AlertSetting[]>('Alerts');
      return plainToClass(AlertSetting, alerts);
    },
    async updateSubscriptionAsync(request) {
      await api.putAsync('Alerts', request);
    },
  };
};

import moment from 'moment';
import { extend } from 'vee-validate';
import { isAfter, isBefore, isSameDay, parse } from 'date-fns';
import { FormatDate } from '~/app/models';

const isDate = (date: any): date is Date =>
  date instanceof Date && !isNaN(date.getTime());

const isFutureDate = (date: Date, inclusive?: boolean) =>
  inclusive
    ? isAfter(date, new Date()) || isSameDay(date, new Date())
    : isAfter(date, new Date());

const isPastDate = (date: Date, inclusive?: boolean) =>
  inclusive
    ? isBefore(date, new Date()) || isSameDay(date, new Date())
    : isBefore(date, new Date());

extend('date', {
  params: ['format'],
  validate(value, args) {
    if (!value) return true;

    if (typeof value !== 'string') {
      return isDate(value);
    }

    const format =
      'format' in args && args.format ? args.format : FormatDate.formatToken;

    try {
      const date = parse(value, format, new Date());

      // right now theres no strict parse so any number for a date will be accepted, validate length as a temp fix
      return !isNaN(date.getTime()) && value.length === format.length;
    } catch (error) {
      return false;
    }
  },
  message: (_field, args) => {
    const format =
      'format' in args && args.format ? args.format : FormatDate.formatToken;
    return `Invalid date format (${format}).`;
  },
});

extend('is_future_date', {
  params: ['format'],
  validate(value, args) {
    if (!value) return true;

    const format =
      'format' in args && args.format ? args.format : FormatDate.formatToken;

    if (moment.isMoment(value)) {
      return isFutureDate(value.toDate());
    }

    if (isDate(value)) {
      return isFutureDate(value);
    }

    if (typeof value === 'string') {
      if (!format) console.warn('Invalid or no date format provided');
      const date = parse(value, format, new Date());
      return isFutureDate(date);
    }

    return false;
  },
  message: (field) => `${field} must be a in the future.`,
});

extend('is_future_or_same_date', {
  params: ['format'],
  validate(value, args) {
    if (!value) return true;

    const format =
      'format' in args && args.format ? args.format : FormatDate.formatToken;

    if (moment.isMoment(value)) {
      return isFutureDate(value.toDate(), true);
    }

    if (isDate(value)) {
      return isFutureDate(value, true);
    }

    if (typeof value === 'string') {
      if (!format) console.warn('Invalid or no date format provided');
      const date = parse(value, format, new Date());
      return isFutureDate(date, true);
    }

    return false;
  },
  message: (field) => `${field} must be a in the future.`,
});

extend('is_past_date', {
  params: ['format'],
  validate(value, args) {
    if (!value) return true;

    const format =
      'format' in args && args.format ? args.format : FormatDate.formatToken;

    if (moment.isMoment(value)) {
      return isPastDate(value.toDate());
    }

    if (isDate(value)) {
      return isPastDate(value);
    }

    if (typeof value === 'string') {
      if (!format) console.warn('Invalid or no date format provided');
      const date = parse(value, format, new Date());
      return isPastDate(date);
    }

    return false;
  },
  message: (field) => `${field} must be in the past.`,
});

extend('is_past_or_same_date', {
  params: ['format'],
  validate(value, args) {
    if (!value) return true;

    const format =
      'format' in args && args.format ? args.format : FormatDate.formatToken;

    if (moment.isMoment(value)) {
      return isPastDate(value.toDate(), true);
    }

    if (isDate(value)) {
      return isPastDate(value, true);
    }

    if (typeof value === 'string') {
      if (!format) console.warn('Invalid or no date format provided');
      const date = parse(value, format, new Date());
      return isPastDate(date, true);
    }

    return false;
  },
  message: (field) => `${field} must be in the past.`,
});

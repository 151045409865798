import { parse, isValid } from 'date-fns';
import { DateOnlyFormatToken, DateTimeFormatToken } from './format-tokens';

/**
 * Parse a string into a Date object using the `DateTimeFormatToken` format token.
 * @param str The string to parse.
 * @returns The parsed Date object, or null if the string could not be parsed.
 */
export function parseDateTime(str: string): Date | null {
  const date = parse(str, DateTimeFormatToken, new Date());
  return isValid(date) ? date : null;
}

/**
 * Parse a string into a Date object using the `DateOnlyFormatToken` format token.
 * @param str The string to parse.
 * @returns The parsed Date object, or null if the string could not be parsed.
 */
export function parseDateOnly(str: string): Date | null {
  const date = parse(str, DateOnlyFormatToken, new Date());
  return isValid(date) ? date : null;
}

/**
 * Parse a string into a Date object so long as it can be parsed as either a date (`DateOnlyFormatToken`) or a date/time (`DateTimeFormatToken`).
 * @param str The string to parse.
 * @returns The parsed Date object, or null if the string could not be parsed.
 */
export function parseDateLoose(str: string): Date | null {
  return parseDateTime(str) || parseDateOnly(str);
}

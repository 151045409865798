
































































import {
  computed,
  defineComponent,
  getCurrentInstance,
  inject,
  onMounted,
  onUnmounted,
  PropType,
  Ref,
  ref,
  toRefs,
} from '@nuxtjs/composition-api';
import { ValidationProvider } from 'vee-validate';
import { InteractionModeFactory } from 'vee-validate/dist/types/modes';
import { watchOnce } from '@vueuse/core';
import {
  SYMBOL_FORM_DIRECTION,
  SYMBOL_FORM_FORM_ITEM_ADD,
  SYMBOL_FORM_FORM_ITEM_REMOVE,
  SYMBOL_FORM_OBSERVER_RULES,
  SYMBOL_FORM_VALIDATION_MODE,
} from './symbols';
import { FormDirection, FormValidationTrigger } from './form.vue';
import WbFormItemContent from './form-item-content.vue';

export default defineComponent({
  name: 'FormItem',
  components: {
    ValidationProvider,
    WbFormItemContent,
  },
  props: {
    /** The label to display. */
    label: {
      type: String as PropType<string>,
      required: true,
    },
    /** Name of the corresponding property on the `Form` components rules object. Only needed when specifying rules on `Form` component. */
    prop: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    /**  The name of the rule property to use for validation. Defaults to the `prop` prop if not set. */
    ruleProp: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    /** Form item rules. */
    rules: {
      type: [String, Object] as PropType<
        string | Record<string, any> | undefined
      >,
      required: false,
      default: undefined,
    },
    /** Indicates if this is a required field. */
    required: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: undefined,
    },
    /** Indicates if an "optional" message should be displayed. */
    optional: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /** Indicates if an asterisk should be displayed. */
    asterisk: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    /** A field description to display within a tooltip. */
    description: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    /** Indicates if the label should be hidden. */
    hideLabel: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /** Form label placement. */
    direction: {
      type: String as PropType<FormDirection | undefined>,
      required: false,
      default: undefined,
    },
  },
  emits: {
    /** Emitted when the field value changes. */
    change: null,
    /** Emitted when the field control receives input.  */
    input: null,
  },
  setup(props, { emit }) {
    const provider = ref<InstanceType<typeof ValidationProvider>>();

    const addSelfToForm = inject<Function>(SYMBOL_FORM_FORM_ITEM_ADD);
    const removeSelfFromForm = inject<Function>(SYMBOL_FORM_FORM_ITEM_REMOVE);

    const instance = getCurrentInstance();

    function forceEmitChange(value: unknown, ...args: any[]) {
      emit('change', value, instance?.proxy, ...args);
    }

    function emitInput(value: unknown, ...args: any[]) {
      emit('input', value, instance?.proxy, ...args);
    }

    onMounted(() => {
      const proxy = instance?.proxy;

      if (!proxy || !addSelfToForm) {
        throw new Error('Unable add FormItem to Form');
      }

      addSelfToForm?.(proxy, provider.value);

      const veeOnBlur = provider.value!.$veeOnBlur;
      provider.value!.$veeOnBlur = (...args: any[]) => {
        if (
          args?.length &&
          args[0] instanceof FocusEvent &&
          args[0].target instanceof HTMLInputElement
        ) {
          // Antd Checkbox does not emit input event when checked state changes, we need to patch this unfortunately
          provider.value?.setFlags({ dirty: true, pristine: false });
        }

        veeOnBlur?.(...args);

        if (provider.value?.flags.dirty) {
          const value = provider.value?.value;
          forceEmitChange(value);
        }
      };

      const veeOnInput = provider.value!.$veeOnInput;
      provider.value!.$veeOnInput = (...args: any[]) => {
        veeOnInput?.(...args);

        const [value] = args;
        emitInput(value, ...args);
      };
    });

    onUnmounted(() => {
      const instance = getCurrentInstance()?.proxy;

      if (!instance || !removeSelfFromForm) {
        throw new Error('Unable add FormItem to Form');
      }

      removeSelfFromForm(instance);
    });

    const validationMode = inject<Ref<FormValidationTrigger>>(
      SYMBOL_FORM_VALIDATION_MODE
    );
    const formDirection = inject<Ref<FormDirection>>(SYMBOL_FORM_DIRECTION);
    const directionComputed = computed(
      () => props.direction ?? formDirection?.value
    );
    const observerRules = inject<Ref<Record<string, any>>>(
      SYMBOL_FORM_OBSERVER_RULES
    );

    const veeValidateMode = computed<
      InteractionModeFactory | 'passive' | 'aggressive'
    >(() => {
      const mode = validationMode?.value;

      if (mode === 'submit') {
        return 'passive';
      }

      if (mode === 'input') {
        return 'aggressive';
      }

      return (_context) => ({ on: ['blur'] });
    });

    const isDirty = computed(() => provider.value?.flags.dirty ?? false);
    const errors = computed(() => provider.value?.errors || []);

    const { prop, rules, required } = toRefs(props);

    const stringRulesToObject = (rules: string) => {
      // const hasParams = (rule: string) => rule.includes(':'); // maybe use regex to match "{any a-z}:{all other including :}"
      // const isBool = (value: string) =>
      //   ['true', 'false'].includes(value.toLowerCase());

      const parts = rules.split('|');
      parts.reduce((_rules, rule) => {
        // // TODO: support and test rule arguments
        // // TODO: support array of args
        // if (hasParams(rule)) {
        //   const [name, params] = rule.split(':');
        //   return {
        //     ..._rules,
        //     [name]: isBool(params) ? Boolean(params.toLowerCase()) : params,
        //   };
        // }

        return {
          ..._rules,
          [rule]: true,
        };
      }, {} as Record<string, any>);
    };

    const combineRules = (
      rules: string | Record<string, any>,
      rulesToAdd: string | Record<string, any>
    ): Record<string, any> => {
      const rulesObject =
        typeof rules === 'string' ? stringRulesToObject(rules) : rules;

      const rulesToAddObject =
        typeof rulesToAdd === 'string'
          ? stringRulesToObject(rulesToAdd)
          : rulesToAdd;

      return {
        ...rulesObject,
        ...rulesToAddObject,
      };
    };

    const computedRules = computed(() => {
      let _rules: Record<string, any> = {};

      const ruleProp = props.ruleProp ?? prop.value;
      const inheritedRules = ruleProp
        ? observerRules?.value?.[ruleProp]
        : undefined;

      if (inheritedRules) {
        _rules = combineRules(_rules, inheritedRules);
      }

      if (rules.value) {
        _rules = combineRules(_rules, rules.value);
      }

      _rules = combineRules(_rules, {
        required: required.value ?? _rules.required ?? false,
      });

      return _rules;
    });

    const isRequiredCrossField = computed(
      () =>
        !!(
          computedRules.value.required_if_empty ||
          computedRules.value.required_if_any_empty ||
          computedRules.value.required_if_all_empty
        )
    );

    const isRequired = computed(
      () =>
        (computedRules.value.required || isRequiredCrossField.value) ?? false
    );

    const validateAsync = (options: { silent: boolean } = { silent: true }) => {
      if (!provider.value) {
        return Promise.reject(
          new Error('[FormItem] ValidationProvider not found.')
        );
      }

      if (options.silent) {
        return provider.value.validateSilent();
      } else {
        return provider.value.validate();
      }
    };

    const resetValidationStateAsync = () => {
      return new Promise<void>((resolve, reject) => {
        watchOnce(
          () => provider.value?.flags,
          () => resolve(),
          { deep: true }
        );

        if (!provider.value) {
          return reject(new Error('[FormItem] ValidationProvider not found.'));
        }

        provider.value.reset();
      });
    };

    return {
      provider,
      isDirty,
      errors,
      veeValidateMode,
      directionComputed,
      computedRules,
      isRequired,
      isRequiredCrossField,
      validateAsync,
      resetValidationStateAsync,
      forceEmitChange,
    };
  },
});

import {
  AutopsyCollection,
  Case,
  DecedentInfo,
  DocumentCollection,
  FuneralHome,
  NotesCollection,
  OrganDonationCollection,
  BelongingsCollection,
  TagsCollection,
  MedicalExaminer,
  InfectiousDisease,
  OriginLocation,
  CaseHold,
  NextOfKinCollection,
  DeathCertificate,
  AutopsyServiceCollection,
  DonationProcurementCollection,
  MedicolegalInvestigationCollection,
} from '~/app/models/case';

function sanitizeSection<TData>(section: TData): TData | undefined {
  if (!section || !(section instanceof Object)) return undefined;

  // replace undefined properties with null
  let hasValue = false;
  for (const [key, value] of Object.entries(section)) {
    if (value === undefined || value === null) {
      (section as any)[key] = null;
    } else {
      hasValue = true;
    }
  }

  return hasValue ? section : undefined;
}

export class UpdateCaseRequest {
  public readonly caseId: string;
  public readonly typeId?: string;
  public readonly storageLocationId?: string;
  public readonly financialIdentificationNumber?: string;
  public readonly decedent?: DecedentInfo;
  public readonly holds?: CaseHold;
  public readonly deathCertificate?: DeathCertificate;
  public readonly autopsies?: AutopsyCollection;
  public readonly autopsyServices?: AutopsyServiceCollection;
  public readonly tags?: TagsCollection;
  public readonly property?: BelongingsCollection;
  public readonly organDonations?: OrganDonationCollection;
  public readonly donationProcurements?: DonationProcurementCollection;
  public readonly originLocation?: OriginLocation;
  public readonly medicalExaminer?: MedicalExaminer;
  public readonly medicolegalInvestigations?: MedicolegalInvestigationCollection;
  public readonly funeralHome?: FuneralHome;
  public readonly infectiousDisease?: InfectiousDisease;
  public readonly nextOfKin?: NextOfKinCollection;
  public readonly notes?: NotesCollection;
  public readonly documents?: DocumentCollection;

  constructor(args?: Case) {
    if (!args?.id)
      throw new Error('Case must have an ID assigned in order to update.');

    this.caseId = args?.id;
    this.typeId = args?.typeId;
    this.storageLocationId = args?.storageLocationId;
    this.financialIdentificationNumber = args?.financialIdentificationNumber;
    this.decedent = sanitizeSection(args?.decedent);
    this.holds = sanitizeSection(args?.holds);
    this.deathCertificate = sanitizeSection(args?.deathCertificate);
    this.autopsies = sanitizeSection(args?.autopsies);
    this.autopsyServices = sanitizeSection(args?.autopsyServices);
    this.tags = sanitizeSection(args?.tags);
    this.property = sanitizeSection(args?.property);
    this.organDonations = sanitizeSection(args?.organDonations);
    this.donationProcurements = sanitizeSection(args?.donationProcurements);
    this.originLocation = sanitizeSection(args?.originLocation);
    this.medicalExaminer = sanitizeSection(args?.medicalExaminer);
    this.medicolegalInvestigations = sanitizeSection(
      args?.medicolegalInvestigations
    );
    this.funeralHome = sanitizeSection(args?.funeralHome);
    this.infectiousDisease = sanitizeSection(args?.infectiousDisease);
    this.nextOfKin = sanitizeSection(args?.nextOfKin);
    this.notes = sanitizeSection(args?.notes);
    this.documents = sanitizeSection(args?.documents);
  }
}

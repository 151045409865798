import Vue from 'vue';
import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import consola from 'consola';
import {
  init as initSentry,
  browserTracingIntegration,
  replayIntegration,
  captureUserFeedback,
  forceLoad,
  onLoad,
  showReportDialog,
} from '@sentry/vue';
import * as CoreSdk from '@sentry/core';
import type { Integration } from '@sentry/types';
import { SentryLogger } from '~/app/core/logging/sentry/logger';
import { IAccessState } from '~/store/access';
import { ITenantState } from '~/store/tenant';

const Sdk = {
  ...CoreSdk,
  ...{ captureUserFeedback, forceLoad, onLoad, showReportDialog },
} as const;

export type SentrySdk = typeof Sdk;

type IntegrationKey = 'replay' | 'tracing';

const integrations: Record<IntegrationKey, Integration> = {
  tracing: browserTracingIntegration(),
  replay: replayIntegration({
    maskAllText: true,
    blockAllMedia: true,
  }),
};

const integrationMap = Object.entries(integrations) as Array<
  [IntegrationKey, Integration]
>;

export default defineNuxtPlugin(({ $log, $config, store }, inject) => {
  const { sentry } = $config;

  const integrations = integrationMap
    .filter(([key, module]) => sentry.integrations?.[key].enabled && module)
    .map(([, integration]) => integration);

  for (const [key, module] of integrationMap) {
    if (sentry.integrations?.[key].enabled && module) {
      consola.info(`🛠️ Sentry "${key}" integration enabled`);
    }
  }

  initSentry({
    Vue,
    dsn: sentry.dsn,
    environment: sentry.environment ?? process.env.NODE_ENV,
    release: `v${$config.app.version} ${$config.app.buildNumber}`,
    attachStacktrace: true,
    integrations,
    beforeSend(event, _hint) {
      const { user } = store.state.access as IAccessState;
      if (user)
        event.user = {
          id: user.id,
          email: user.emailAddress,
          firstName: user.firstName,
          lastName: user.lastName,
        };

      const { tenant } = store.state.tenant as ITenantState;
      if (tenant)
        event.tags = {
          ...(event.tags || {}),
          tenantId: tenant.id,
          tenant: tenant.name,
        };

      if (sentry.reportDialog?.enabled && event.exception) {
        showReportDialog({ eventId: event.event_id as string });
      }

      return event;
    },
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications.',
    ],

    // #region Performance Monitoring
    tracesSampleRate: sentry.integrations?.tracing.sampleRate ?? 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: sentry.integrations?.tracing.propagationTargets,
    // #endregion

    // #region Session Replay
    replaysSessionSampleRate:
      // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      // prettier-ignore
      sentry.integrations?.replay.sampleRate ?? process.env.NODE_ENV === 'development' ? 1.0 : 0.1,
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
    // #endregion
  });

  $log.addLogger(new SentryLogger());

  inject('sentry', Sdk);
});

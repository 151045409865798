import { Type } from 'class-transformer';
import { FormatDate, IsFormatDate } from '../../dates';
import { CaseAction } from '../case-action';
import { CaseNotice } from '../notice';
import { CaseTypeDictionaryItem } from '../../dictionaries';
import { CaseSummaryTags } from './tags';

export class ReservationCaseSummary {
  public readonly id!: string;

  public readonly caseType?: CaseTypeDictionaryItem;

  public readonly decedentName?: string;

  public readonly decedentAlias?: string;

  public readonly decedentMedicalRecordNumber?: string;

  @IsFormatDate
  public readonly decedentExpirationDate?: FormatDate;

  @Type(() => CaseSummaryTags)
  public readonly tags!: CaseSummaryTags;

  @Type(() => CaseAction)
  public readonly checkIn!: CaseAction;

  @Type(() => CaseNotice)
  public readonly notices!: CaseNotice[];
}

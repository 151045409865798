







































// @ts-ignore
import enUSLocale from 'ant-design-vue/lib/locale-provider/en_US';
import {
  computed,
  defineComponent,
  ref,
  watch,
  useContext,
  useRoute,
} from '@nuxtjs/composition-api';
import { useResizeObserver } from '@vueuse/core';
import { useModule } from '~/app/core/module-system';
import { ConsoleLogger } from '~/app/core/logging';
import { useServiceWorker } from '~/app/composables/use-service-worker';
import { LayoutModule } from '~mod:layout';
import TheAside from '~mod:layout/components/organisms/aside/the-aside.vue';
import TheAppUpdateAlert from '~/app/modules/layout/components/molecules/the-update-alert.vue';
import TheBreadCrumbs from '~/app/modules/layout/components/molecules/the-bread-crumbs.vue';
import TheBottomBar from '~mod:layout/components/organisms/the-bottom-bar.vue';
import TheWelcomeMenu from '~/app/modules/layout/components/organisms/the-welcome-menu.vue';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    TheAside,
    TheAppUpdateAlert,
    TheBreadCrumbs,
    TheBottomBar,
    TheWelcomeMenu,
  },
  setup() {
    const { $auth, $config } = useContext();
    const version = `${$config.app.version} ${$config.app.buildNumber}`;

    const isAuthenticated = computed(() => $auth.isAuthenticated.value);

    const { needsRefresh } = useServiceWorker();
    const isUpdateAlertVisible = ref(needsRefresh.value);
    watch(needsRefresh, (refresh) => (isUpdateAlertVisible.value = refresh));

    const { breadCrumbs } = useModule(LayoutModule);

    const isMenuCollapsed = ref(true);
    const toggleMenu = () => (isMenuCollapsed.value = !isMenuCollapsed.value);

    // collapse menu on navigation
    const route = useRoute();
    watch(
      () => route.value.path,
      () => {
        if (isMenuCollapsed.value) return;
        isMenuCollapsed.value = true;
      }
    );

    // Compute aside width style variable based on its content
    const aside = ref<HTMLDivElement>();
    const asideWidth = ref(250);
    useResizeObserver(aside, ([{ target }]) => {
      asideWidth.value = target.clientWidth;
    });

    return {
      isAuthenticated,
      version,
      locale: enUSLocale,
      isUpdateAlertVisible,
      isMenuCollapsed,
      toggleMenu,
      breadCrumbParts: breadCrumbs.parts,
      aside,
      asideWidth,
    };
  },
  errorCaptured: (err, vm, info) => {
    try {
      vm.$log.error(
        err,
        {
          componentName: vm.$options.name!,
          info,
        },
        { ui: true }
      );
    } finally {
      const hasConsoleLogger = vm.$log.getLogger(ConsoleLogger);
      !hasConsoleLogger && console.error(err);
    }

    return false;
  },
});

import { Type } from 'class-transformer';
import { CaseService } from './case-service';
import { CaseServiceCollection } from './case-services';

export class MedicolegalInvestigation extends CaseService {}

export class MedicolegalInvestigationCollection extends CaseServiceCollection<MedicolegalInvestigation> {
  @Type(() => MedicolegalInvestigation)
  public readonly instances!: MedicolegalInvestigation[];

  constructor(args?: Partial<MedicolegalInvestigationCollection>) {
    super(args);
    this.instances = args?.instances
      ? args.instances.map((i) => new MedicolegalInvestigation(i))
      : [];
  }
}

import { plainToClass } from 'class-transformer';
import { PartialApiFactory } from '../partial-factory';
import { IApiFactory } from '../../IApiFactory';
import { ReservationCaseSummary } from '~/app/models';

export const createReservationsApi: PartialApiFactory<
  IApiFactory,
  'reservations'
> = (api, _config) => {
  return {
    async getCaseSummariesAsync() {
      const summaries = await api.getAsync<ReservationCaseSummary[]>(
        'Reservations'
      );

      return plainToClass(ReservationCaseSummary, summaries);
    },
  };
};

import Vue, { ComponentOptions, PluginObject, VNode } from 'vue';
import { PropType } from '@vue/composition-api';
import { Fragment } from 'vue-frag';

Vue.component('Fragment', Fragment as ComponentOptions<any>);

/** Automatically register all CureConnect UI kit components as globals. */
const globals = require.context('~/app/components/ui', true, /index\.ts/);

globals.keys().forEach((fileName: string) => {
  const component = globals<PluginObject<void>>(fileName);
  Vue.use(component.default || component);
});

/** Component to accept VNode's and renders HTML/Component counterparts */
Vue.component('VNodes', {
  functional: true,
  props: {
    nodes: {
      required: true,
      type: [Object, Array] as PropType<VNode | VNode[]>,
    },
  },
  render: (_h, ctx) => ctx.props.nodes,
});

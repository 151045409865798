import { render, staticRenderFns } from "./menu-button.vue?vue&type=template&id=5a4f9bd5&scoped=true&"
import script from "./menu-button.vue?vue&type=script&lang=ts&"
export * from "./menu-button.vue?vue&type=script&lang=ts&"
import style0 from "./menu-button.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./menu-button.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./menu-button.vue?vue&type=style&index=2&id=5a4f9bd5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a4f9bd5",
  null
  
)

export default component.exports









import {
  defineComponent,
  PropType,
  provide,
  ref,
  toRefs,
  computed,
} from '@nuxtjs/composition-api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { watchOnce } from '@vueuse/core';
import { cloneDeep } from 'lodash-es';
import {
  SYMBOL_FORM_OBSERVER,
  SYMBOL_FORM_DIRECTION,
  SYMBOL_FORM_OBSERVER_RULES,
  SYMBOL_FORM_VALIDATION_MODE,
  SYMBOL_FORM_FORM_ITEM_ADD,
  SYMBOL_FORM_FORM_ITEM_REMOVE,
} from './symbols';
import FormItem from './form-item.vue';

export type FormDirection = 'horizontal' | 'vertical';
export type FormValidationTrigger = 'blur' | 'input' | 'submit';

export default defineComponent({
  name: 'Form',
  components: {
    ValidationObserver,
  },
  props: {
    /** DOM element tag to render the root element as. @default 'span' */
    as: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    /** Value binding model, only needed if you need to reset the form to its initial state.  */
    model: {
      type: Object as PropType<Record<string, any> | undefined>,
      required: false,
      default: undefined,
    },
    /** Form rules. */
    rules: {
      type: Object as PropType<Record<string, string | any> | undefined>,
      required: false,
      default: undefined,
    },
    validationMode: {
      type: String as PropType<FormValidationTrigger>,
      required: false,
      default: 'blur',
    },
    /** Form label placement. */
    direction: {
      type: String as PropType<FormDirection>,
      required: false,
      default: 'vertical',
    },
  },
  setup(props, { emit }) {
    const observer = ref<InstanceType<typeof ValidationObserver>>();
    provide(SYMBOL_FORM_OBSERVER, observer);

    const { direction, rules, validationMode } = toRefs(props);
    provide(SYMBOL_FORM_DIRECTION, direction);
    provide(SYMBOL_FORM_OBSERVER_RULES, rules);
    provide(SYMBOL_FORM_VALIDATION_MODE, validationMode);

    const formItems = new Map<
      InstanceType<typeof FormItem>,
      InstanceType<typeof ValidationProvider>
    >();

    const onFieldChange = (
      value: unknown,
      field: InstanceType<typeof FormItem>
    ) => {
      emit('change', field, value);
    };

    const onFieldInput = (
      value: unknown,
      field: InstanceType<typeof FormItem>
    ) => {
      emit('input', field, value);
    };

    const addFormItem = (
      item: InstanceType<typeof FormItem>,
      provider: InstanceType<typeof ValidationProvider>
    ) => {
      formItems.set(item, provider);
      item.$on('change', onFieldChange);
      item.$on('input', onFieldInput);
    };

    const removeFormItem = (item: InstanceType<typeof FormItem>) => {
      if (formItems.has(item)) {
        formItems.delete(item);
        item.$off('change', onFieldChange);
        item.$off('input', onFieldInput);
      }
    };

    provide(SYMBOL_FORM_FORM_ITEM_ADD, addFormItem);
    provide(SYMBOL_FORM_FORM_ITEM_REMOVE, removeFormItem);

    const initialModelState: Record<string, any> | undefined = props.model
      ? cloneDeep(props.model)
      : undefined;

    const isDirty = computed(() => observer.value?.flags.dirty ?? false);
    const errors = computed(() => observer.value?.errors || {});

    const validateAsync = () => {
      if (!observer.value) {
        return Promise.reject(
          new Error('[Form] ValidationObserver not found.')
        );
      }

      return observer.value.validate();
    };

    const validateWithErrorsAsync = async () => {
      if (!observer.value) {
        return Promise.reject(
          new Error('[Form] ValidationObserver not found.')
        );
      }

      const result = await observer.value.validateWithInfo();
      return { isValid: result.isValid, errors: result.errors };
    };

    const resetValidationStateAsync = () => {
      return new Promise<void>((resolve, reject) => {
        if (!observer.value) {
          return reject(new Error('[Form] ValidationObserver not found.'));
        }

        if (observer.value.flags.pristine) {
          return resolve();
        }

        watchOnce(
          () => observer.value?.flags,
          () => resolve(),
          { deep: true }
        );

        observer.value.reset();
      });
    };

    const resetAsync = async () => {
      const { model } = toRefs(props);
      if (!model.value) {
        throw new Error('[Form] "model" prop is required to reset state.');
      }

      if (model.value && initialModelState) {
        const _model: Record<string, unknown> = {};

        for (const prop in initialModelState) {
          _model[prop] = initialModelState[prop];
        }

        emit('update:model', _model);
      }

      await resetValidationStateAsync();
    };

    const findFormItem = (
      prop: string
    ): InstanceType<typeof FormItem> | undefined => {
      return [...formItems.keys()].find((formItem) => formItem.prop === prop);
    };

    const handleSubmit = async (func: Function) => {
      const isValid = await validateAsync();
      isValid && func();
    };

    /** This is more of an escape hatch and should not normally be used. Usage examples may include:
     * Adding or remove form items dynamically - these form items will initially be in an "untouched" state causing the form as a whole to count these items as not "dirty". If this action is to be treated as a "dirty" state, then this allows the consumer to pivot off a forced change event.
     */
    const forceEmitChange = () => {
      emit('change', null, null);
    };

    return {
      initialModelState,
      observer,
      formItems,
      isDirty,
      errors,
      validateAsync,
      validateWithErrorsAsync,
      resetAsync,
      resetValidationStateAsync,
      findFormItem,
      handleSubmit,
      forceEmitChange,
    };
  },
});

import { plainToClass } from 'class-transformer';
import { PartialApiFactory } from '../partial-factory';
import { IApiFactory } from '../../IApiFactory';
import { OutboxCaseSummary } from '~/app/models';

export const createOutboxApi: PartialApiFactory<IApiFactory, 'outbox'> = (
  api
) => {
  return {
    async getSummariesAsync(request) {
      const summaries = await api.getAsync<OutboxCaseSummary[]>('Outbox', {
        params: request as Indexable,
      });

      return plainToClass(OutboxCaseSummary, summaries);
    },
  };
};

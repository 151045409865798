import { MutationTree } from 'vuex';
import { ICaseDetailsState } from './state';
import { Case } from '~/app/models';

export enum Mutation {
  init = 'INITIALIZE',
  reset = 'RESET',
  setCase = 'SET_CASE',
}

export const mutations: MutationTree<ICaseDetailsState> = {
  [Mutation.init](state, caseDetails: Case) {
    state.case = caseDetails || new Case();
  },
  [Mutation.reset](state) {
    state.case = null;
  },
  [Mutation.setCase](state, caseDetails: Case) {
    if (!caseDetails) throw new Error('case must have a value.');
    state.case = caseDetails;
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['wb-form-item', ("wb-form-item__" + _vm.directionComputed)]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideLabel),expression:"!hideLabel"}],staticClass:"wb-form-item__label"},[_vm._t("label-left",null,null,{
        errors: _vm.errors,
        isDirty: _vm.isDirty,
        isRequired: _vm.computedRules.required,
        isRequiredCrossField: !_vm.computedRules.required && _vm.isRequiredCrossField,
      }),_vm._v(" "),_c('label',{attrs:{"for":_vm.$attrs.for || (_vm.label + "_input")}},[_vm._t("label",[_vm._v("\n        "+_vm._s(_vm.label)+"\n      ")],null,{
          label: _vm.label,
          errors: _vm.errors,
          isDirty: _vm.isDirty,
          isRequired: _vm.computedRules.required,
          isRequiredCrossField:
            !_vm.computedRules.required && _vm.isRequiredCrossField,
        }),_vm._v(" "),(_vm.description)?_c('a-tooltip',{attrs:{"title":_vm.description}},[_c('wb-icon',{attrs:{"name":"question","type":"bold"}})],1):_vm._e(),_vm._v(" "),(!_vm.isRequired && _vm.optional)?_c('em',{staticClass:"optional"},[_vm._v("Optional")]):_vm._e(),_vm._v(" "),(_vm.isRequired && _vm.asterisk && !_vm.optional)?_c('em',{staticClass:"asterisk",class:{
          'cross-field': !_vm.computedRules.required && _vm.isRequiredCrossField,
        }},[_vm._v("*")]):_vm._e()],2),_vm._v(" "),_vm._t("label-right",null,null,{
        errors: _vm.errors,
        isDirty: _vm.isDirty,
        isRequired: _vm.computedRules.required,
        isRequiredCrossField: !_vm.computedRules.required && _vm.isRequiredCrossField,
      })],2),_vm._v(" "),_c('wb-form-item-content',{staticClass:"wb-form-item__content",attrs:{"errors":_vm.errors}},[_c('validation-provider',{ref:"provider",attrs:{"mode":_vm.veeValidateMode,"vid":_vm.prop,"name":_vm.label,"rules":_vm.computedRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var validate = ref.validate;
      var errors = ref.errors;
return [_vm._t("default",null,null,{ validate: validate, errors: errors, isDirty: _vm.isDirty })]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
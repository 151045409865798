import { Transform } from 'class-transformer';
import { FormatDate } from '../format-date';
import { FormatDateTime } from '../format-datetime';

/** Specifies the type of the property as @see FormatDate. Required for use with `class-transformer`. */
export const IsFormatDate: PropertyDecorator = Transform(({ value }) =>
  value ? new FormatDate(value) : undefined
);

/** Specifies the type of the property as @see FormatDateTime. Required for use with `class-transformer`. */
export const IsFormatDateTime: PropertyDecorator = Transform(({ value }) =>
  value ? new FormatDateTime(value) : undefined
);

import { plainToClass } from 'class-transformer';
import { PartialApiFactory } from '../partial-factory';
import { IApiFactory } from '../../IApiFactory';
import { AccessScopeMasterList, User, UserInvitation } from '~/app/models';

export const createUserApi: PartialApiFactory<IApiFactory, 'user'> = (api) => {
  return {
    registration: {
      async registerAsync(request) {
        const user = await api.postAsync<User>('Users/Self', request);
        return plainToClass(User, user);
      },
    },
    invitations: {
      async acceptAsync(request) {
        await api.putAsync<void>(
          `Users/Invitation/${request.invitationId}/Accept`
        );
      },
      async getAllAsync() {
        const invites = await api.getAsync<UserInvitation[]>(
          'Users/Invitation'
        );
        return plainToClass(UserInvitation, invites);
      },
      async createAsync(request) {
        await api.postAsync<void>('Users/Invitation', request);
      },
      async resendAsync(request) {
        const invite = await api.postAsync<UserInvitation>(
          `Users/Invitation/${request.invitationId}`
        );
        return plainToClass(UserInvitation, invite);
      },
      async revokeAsync(request) {
        await api.deleteAsync<void>(`Users/Invitation/${request.invitationId}`);
      },
    },
    async resetPasswordAsync(request) {
      await api.postAsync<void>(`Users/${request.userId}/ResetPassword`);
    },
    async getAccessScopeMasterListAsync() {
      const scopes = await api.getAsync<AccessScopeMasterList>('Users/Scopes');
      return plainToClass(AccessScopeMasterList, scopes);
    },
    async getCurrentAsync() {
      const user = await api.getAsync<User>('Users/Self');
      return plainToClass(User, user);
    },
    async getAsync(userId) {
      const user = await api.getAsync<User>(`Users/${userId}`);
      return plainToClass(User, user);
    },
    async getAllAsync() {
      const users = await api.getAsync<User[]>('Users');
      return plainToClass(User, users);
    },
    async updateAsync(request) {
      const user = await api.putAsync<User>(`Users/${request.userId}`, request);
      return plainToClass(User, user);
    },
    async createAsync(request) {
      const user = await api.postAsync<User>('Users', request);
      return plainToClass(User, user);
    },
    async deleteAsync(userId) {
      await api.deleteAsync<User>(`Users/${userId}`);
    },
  };
};

import { format } from 'date-fns';
import { DateOnlyFormatToken } from './format-tokens';
import { parseDateLoose } from './utils';

export class FormatDate extends Date {
  static readonly formatToken = DateOnlyFormatToken;

  constructor(
    date?: string | number | Date,
    month?: number,
    day?: number,
    hours?: number,
    minutes?: number,
    seconds?: number,
    ms?: number
  ) {
    if (!date) super();
    else if (typeof date === 'number' && typeof month === 'number')
      super(date, month, day, hours, minutes, seconds, ms);
    else if (typeof date === 'number') super(date);
    else if (typeof date === 'string') {
      super(parseDateLoose(date) ?? 'Invalid Date');
    } else super(date);
  }

  public toString() {
    const formatString = format(this, FormatDate.formatToken) as string;
    return formatString;
  }

  public toISOString() {
    return this.toString();
  }

  public toJSON(_key?: any) {
    return this.toString();
  }
}

import { Language } from '~/app/models';

export const DEFAULT_LANGUAGE = Object.freeze<Language>({
  bodybox: {
    singular: 'Bodybox',
    plural: 'Bodyboxes',
    article: 'a',
  },
  reservation: {
    singular: 'Incoming Case',
    plural: 'Incoming Cases',
    article: 'an',
  },
  case: {
    singular: 'Case',
    plural: 'Cases',
    article: 'a',
  },
  outbox: {
    singular: 'Outbox',
    plural: 'Outboxes',
    article: 'an',
  },
  decedent: {
    singular: 'Decedent',
    plural: 'Decedents',
    article: 'the',
  },
  autopsy: {
    singular: 'Autopsy',
    plural: 'Autopsies',
    article: 'an',
  },
  tissue: {
    singular: 'Tissue',
    plural: 'Tissues',
    article: 'a',
  },
  funeralHome: {
    singular: 'Funeral Home',
    plural: 'Funeral Homes',
    article: 'a',
  },
  medicalExaminer: {
    singular: 'Medical Examiner',
    plural: 'Medical Examiners',
    article: 'the',
  },
  nextOfKin: {
    singular: 'Next of Kin',
    plural: 'Next of Kin',
    article: 'a',
  },
});

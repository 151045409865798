






























import {
  computed,
  defineComponent,
  PropType,
  toRefs,
} from '@nuxtjs/composition-api';
import type { Route } from 'vue-router';
import type { IBreadCrumbRoute } from '~mod:layout';

export default defineComponent({
  name: 'TheBreadCrumbs',
  props: {
    current: {
      type: Object as PropType<Route>,
      required: true,
    },
    path: {
      type: Array as PropType<IBreadCrumbRoute[]>,
      required: true,
    },
    rootRoute: {
      type: String as PropType<string>,
      required: false,
      default: '/',
    },
  },
  emits: {
    back: null,
  },
  setup(props) {
    const { current, path, rootRoute } = toRefs(props);

    const parts = computed(() =>
      path.value.map((part) => ({
        breadcrumbName: part.name,
        path: part.path || '',
      }))
    );

    const isRoot = computed(() => current.value.path === rootRoute.value);

    return { parts, isRoot };
  },
});








































































import { defineComponent } from '@nuxtjs/composition-api';
import { useVModel } from '@vueuse/core';

export default defineComponent({
  name: 'FilterPill',
  model: {
    prop: 'active',
    event: 'update:active',
  },
  props: {
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    expression: {
      type: [String, Boolean],
      required: false,
      default: true,
    },
  },
  emits: ['update:active', 'activate', 'clear'],
  setup(props, { emit }) {
    const model = useVModel(props, 'active', emit);

    function activate() {
      model.value = true;
      emit('activate');
      return model.value;
    }

    function clear() {
      model.value = false;
      emit('clear');
      return true;
    }

    return { model, activate, clear };
  },
});

import { plainToClass } from 'class-transformer';
import { PartialApiFactory } from '../partial-factory';
import { IApiFactory } from '../../IApiFactory';
import { CreateCaseRequest } from '../../requests/cases/create-case-request';
import { Case, CaseSummary, TransportPermissionReport } from '~/app/models';

export const createCasesApi: PartialApiFactory<IApiFactory, 'cases'> = (
  api
) => {
  return {
    async getAsync(caseId) {
      const details = await api.getAsync<Case>(`UI/Cases/${caseId}`);
      return plainToClass(Case, details);
    },
    async getOpenAsync() {
      const summaries = await api.getAsync<CaseSummary[]>('Cases/Open');
      return Object.freeze(plainToClass(CaseSummary, summaries));
    },
    async createAsync(request: CreateCaseRequest) {
      const details = await api.postAsync<Case>('Cases', request);
      return plainToClass(Case, details);
    },
    async createAndCheckInAsync(request) {
      const details = await api.postAsync<Case>('Cases/CheckIn', request);
      return plainToClass(Case, details);
    },
    async createAndCheckOutAsync(request) {
      const details = await api.postAsync<Case>('Cases/CheckOut', request);
      return plainToClass(Case, details);
    },
    async updateAsync(request) {
      const details = await api.putAsync<Case>(
        `Cases/${request.caseId}`,
        request
      );
      return plainToClass(Case, details);
    },
    async deleteAsync(request) {
      const details = await api.deleteAsync<Case>(`Cases/${request.caseId}`);
      return plainToClass(Case, details);
    },
    async openAsync(caseId) {
      const details = await api.putAsync<Case>(`Cases/${caseId}/open`);
      return plainToClass(Case, details);
    },
    async closeAsync(caseId) {
      const details = await api.putAsync<Case>(`Cases/${caseId}/close`);
      return plainToClass(Case, details);
    },
    transport: {
      async checkInAsync(request) {
        const details = await api.putAsync<Case>(
          `Cases/${request.caseId}/CheckIn`,
          request
        );
        return plainToClass(Case, details);
      },
      async checkoutAsync(request) {
        const details = await api.putAsync<Case>(
          `Cases/${request.caseId}/CheckOut`,
          request
        );
        return plainToClass(Case, details);
      },
      async transferInternalAsync(request) {
        const details = await api.putAsync<Case>(
          `Cases/${request.caseId}/Transfer`,
          request
        );
        return plainToClass(Case, details);
      },
      transferExternalAsync(_request) {
        throw new Error('Not implemented');
        // const details = await api.putAsync<Case>(
        //   `Cases/${request.caseId}/Transfer`,
        //   request
        // );
        // return plainToClass(Case, details);
      },
      async createCheckOutPermissionReportAsync(request) {
        const report = await api.postAsync<TransportPermissionReport>(
          'Cases/CheckOut/Permission',
          request
        );
        return plainToClass(TransportPermissionReport, report);
      },
    },
    documents: {
      async uploadAsync(request) {
        const fileIds = await api.postAsync<string[]>(
          'Cases/Documents',
          request,
          {
            options: {
              // let the browser decide the content type
              headers: { 'Content-Type': undefined },
            },
          }
        );

        return fileIds;
      },
      async getAsync(request) {
        const { caseId, documentId } = request;
        const file = await api.getAsync<any>(
          `Cases/${caseId}/Documents/${documentId}`
        );
        return file;
      },
    },
  };
};

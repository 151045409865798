import { CaseAction } from '../case-action';
import {
  FormatDate,
  FormatDateTime,
  IsFormatDate,
  IsFormatDateTime,
} from '../../dates';

export class OutboxCaseSummary {
  public readonly id!: string;

  public readonly isOpen!: boolean;

  public readonly decedentName!: string;

  public readonly decedentAlias?: string;

  @IsFormatDate
  public readonly decedentExpirationDate!: FormatDate;

  @IsFormatDateTime
  public readonly lastCheckoutDate!: FormatDateTime;

  public readonly checkedOutBy!: string;

  public readonly lastBodybox!: string;

  public readonly checkIn!: CaseAction;
}

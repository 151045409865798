import { plainToClass } from 'class-transformer';
import { PartialApiFactory } from '../partial-factory';
import { IApiFactory } from '../../IApiFactory';
import { Bodybox, BodyboxCapacity, BodyboxCaseSummary } from '~/app/models';

export const createBodyboxesApi: PartialApiFactory<IApiFactory, 'bodyboxes'> = (
  api,
  _config
) => {
  return {
    async getAsync() {
      const boxes = await api.getAsync<Bodybox[]>('Bodyboxes');
      return plainToClass(Bodybox, boxes);
    },
    async getCaseSummariesAsync(bodyboxId: string) {
      const summaries = await api.getAsync<BodyboxCaseSummary[]>(
        `Bodyboxes/${bodyboxId}/Cases`
      );

      summaries.sort((a, b) => {
        if (!a.decedentExpirationDate && !b.decedentExpirationDate) {
          return 0;
        }

        if (a.decedentExpirationDate && !b.decedentExpirationDate) {
          return 1;
        }

        if (!a.decedentExpirationDate && b.decedentExpirationDate) {
          return -1;
        }

        return a.decedentExpirationDate! < b.decedentExpirationDate! ? -1 : 1;
      });

      return plainToClass(BodyboxCaseSummary, summaries);
    },
    async getCapacitiesAsync() {
      const capacities = await api.getAsync<BodyboxCapacity[]>(
        'Bodyboxes/Capacities'
      );
      return plainToClass(BodyboxCapacity, capacities);
    },
    async addAsync(request) {
      const bodybox = await api.postAsync<Bodybox>('Bodyboxes', request);
      return plainToClass(Bodybox, bodybox);
    },
    async updateAsync(request) {
      const bodybox = await api.putAsync<Bodybox>(
        `Bodyboxes/${request.bodyboxId}`,
        request
      );
      return plainToClass(Bodybox, bodybox);
    },
    async deleteAsync(bodyboxId) {
      await api.deleteAsync<void>(`Bodyboxes/${bodyboxId}`);
    },
  };
};
